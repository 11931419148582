<template>
  <div v-if="isUserLoaded" class="modal modal-carry-wallet fade in" tabindex="-1" role="dialog" id="modal-carry-wallet">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a @click="$root.closeModal('modal-carry-wallet')" class="modal-header" data-dismiss="modal">
          <svg xmlns="http://www.w3.org/2000/svg" width="15.153" height="13.639" viewBox="0 0 15.153 13.639">
            <path
              d="M8.27,107.648l-2.8-2.8h8.66a1.026,1.026,0,0,0,1.026-1.026v-1.026a1.026,1.026,0,0,0-1.026-1.026H5.467l2.8-2.8a1.026,1.026,0,0,0,0-1.451l-.726-.726a1.026,1.026,0,0,0-1.451,0L.3,102.579a1.026,1.026,0,0,0,0,1.451l5.794,5.794a1.026,1.026,0,0,0,1.451,0l.726-.726a1.026,1.026,0,0,0,0-1.451Z"
              transform="translate(0.001 -96.485)" fill="#005aa1" />
          </svg>
          <h2 class="modal-title">Carregar carteira</h2>
        </a>
        <div class="modal-body">
          <form ref="formPayment" @submit.prevent="handleSubmit">
            <div class="modal-body-carry-wallet">
              <div class="modal-body-carry-wallet-header">
                <h2>Selecione o valor:</h2>
              </div>
              <div class="modal-body-carry-wallet-element">
                <div class="container">
                  <div class="row">
                    <div class="wallet-values col p-1" v-for="n in rechargeOptions" :key="n" @click="addBalance(n)">
                      <a>{{ n | money }}</a>
                    </div>
                  </div>
                </div>
                <div class="modal-body-carry-wallet-header">
                  <h2>Caso prefira, escreva abaixo:</h2>
                </div>
                <div class="wallet-personalize-value" v-if="user.unit.has_float_recharges">
                  <input type="number" name="price" v-model="balance" step="0.01" max="200" maxlength="6"
                    @input="validateInput" required />
                  <small class="form-text text-muted">Pode-se introduzir valores com cêntimos</small>
                </div>
                <div class="wallet-personalize-value" v-else>
                  <input type="number" name="price" v-model="balance" step="1" max="200" maxlength="6"
                    @input="validateInput" required />
                  <small class="form-text text-muted">Apenas números inteiros</small>
                </div>

              </div>
            </div>
            <div class="modal-body-payment">
              <div class="modal-body-payment-header">
                <h2>Pagamento MBWAY</h2>
              </div>
              <div class="modal-body-payment-element">
                <div class="content-input">
                  <img class="img-fluid" src="../assets/img/schedules/mb-way.png" alt="mb-way" title="mb-way" />
                  <input type="tel" name="mobile" v-model="phone" placeholder="Nº telemóvel" maxlength="9" required />
                </div>
              </div>
            </div>
            <button type="submit" :class="`modal-body-btn-payment` +
              (isBalanceValid == false ? ' btn btn-secondary' : '')
              " :disabled="isBalanceValid == false">
              Pagamento
            </button>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import User from "@/models/User";

export default {
  data() {
    return {
      balance: "",
      phone: "",
      isBalanceValid: false,
      rechargeOptions: [],
      routeName: "",
      isUserLoaded: false,
    };
  },
  computed: {
    ...mapGetters("User", ["user", "unit", "minimum_recharge"]),
  },
  methods: {
    ...mapActions("User", ["addBalanceUser"]),
    addBalance(balance) {
      this.balance = balance;
    },
    async handleSubmit() {
      let formData = this.$root.getAllData(this.$refs.formPayment);

      formData.unit_id = this.unit;
      if (this.$refs.formPayment.checkValidity()) {
        await this.addBalanceUser(formData);
      } else {
        this.$refs.formPayment.reportValidity();
      }
    },
    getRechargeOptions() {
      this.rechargeOptions = [];
      console.log("minimum_recharge:", this.minimum_recharge); // Adicionado para depuração
      if (this.minimum_recharge === 0) {
        this.rechargeOptions = [5, 10, 15, 20, 25, 30];
      } else {
        let startValue = this.minimum_recharge;
        for (let i = 0; i < 6; i++) {
          this.rechargeOptions.push(startValue + i * 5);
        }
      }
      this.rechargeOptions = this.rechargeOptions.filter((value) => value > 0);
      console.log("rechargeOptions:", this.rechargeOptions); // Adicionado para depuração
    },
    formatBalance() {
      if (this.user.unit.has_float_recharges) {
        if (this.balance.includes('.')) {
          const [integer, decimal] = this.balance.split('.');
          this.balance = `${integer}.${decimal.slice(0, 2)}`;
        }
      } else {
        this.balance = this.balance.split('.')[0];
      }
    },
    validateInput(event) {
      let value = event.target.value;

      // Ensure value is a valid number
      if (isNaN(value)) {
        event.target.value = '';
        this.balance = '';
        return;
      }

      // Limit to two decimal places
      if (this.user.unit.has_float_recharges) {
        if (value.includes('.')) {
          const [integer, decimal] = value.split('.');
          event.target.value = `${integer}.${decimal.slice(0, 2)}`;
        }
      } else {
        event.target.value = value.split('.')[0];
      }

      // Ensure value is within range
      let numericValue = parseFloat(event.target.value);
      if (numericValue < 0) {
        event.target.value = 0;
      } else if (numericValue > 200) {
        event.target.value = 200;
      }

      this.balance = event.target.value;
      this.checkBalanceValidity();
    },
    checkBalanceValidity() {
      this.isBalanceValid = this.balance > 0 && this.balance >= this.minimum_recharge;
    },
  },
  watch: {
    user(newUser) {
      if (newUser) {
        this.isUserLoaded = true;
        this.phone = newUser.mobile;
        this.getRechargeOptions(); // Certifique-se de chamar a função aqui também
      }
    },
    balance() {
      this.checkBalanceValidity();
    },
    minimum_recharge() {
      this.getRechargeOptions();
    },
  },
  async created() {
    let eurest_unit = this.unit;
    if (!window.location.pathname.includes("login")) {
      try {
        const response = await User.getMinimumRecharge(eurest_unit);
        if (response.data.minimum_recharge) {
          this.$store.dispatch("User/setMinimumRecharge", {
            minimum_recharge: response.data.minimum_recharge,
          });
          this.getRechargeOptions();
        }
      } catch (error) {
        console.error();
      }
    }
  },
};
</script>

<style scoped>
.has_required {
  text-decoration: line-through;
  color: #689868;
}

.btn-inactive {
  background-color: #e0e0e0;
  color: #fff;
}
</style>